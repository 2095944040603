<template>
  <div class="editor-bar" v-resize="onResize">
    <fluid-bar :landscape="landscape">
      <bar-button icon="monitor-edit" :to="editorLink" :active="true" />

      <bar-button
        v-show="showPreview"
        :active="settings.editor.preview"
        icon="monitor-eye"
        :trigger="togglePreview"
      />

      <bar-button icon="undo" :trigger="undo" class="divider before" />
      <bar-button icon="redo" :trigger="redo" />
      <!-- / -->
      <bar-button class="divider before" icon="copy" :trigger="copy" />
      <bar-button icon="paste" :trigger="paste" />
      <bar-button
        id="charButton"
        icon="omega"
        :trigger="toggleChars"
        :active="showChars"
      />
      <!-- / -->
      <bar-button class="divider before" icon="code-copy">
        <template-dialog />
      </bar-button>
      <bar-button icon="code-prettier" :trigger="prettifyCode" />
    </fluid-bar>

    <fluid-bar class="chars-bar" v-if="showChars" :landscape="landscape">
      <bar-button
        v-for="(char, charIndex) in specialsChars"
        :key="charIndex"
        :icon="char.icon"
        @click="insertChar(char.symbol)"
      />
    </fluid-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { prettify } from '../../utils/code-utils'

import TemplateDialog from '../dialog/TemplateDialog.vue'
import BarButton from '../button/BarButton.vue'
import FluidBar from './FluidBar.vue'

export default {
  name: 'EditorBar',
  components: {
    TemplateDialog,
    BarButton,
    FluidBar
  },
  props: {
    landscape: Boolean,
    layout: String,
    value: Boolean
  },
  data: () => ({
    showChars: false,
    showPreview: false,
    specialsChars: [
      { icon: 'char-up-arrow', symbol: '↑' },
      { icon: 'char-down-arrow', symbol: '↓' },
      { icon: 'char-measure', symbol: '|' },
      { icon: 'char-empty', symbol: '-' },
      { icon: 'char-anchor', symbol: '~' },
      { icon: 'char-median', symbol: '·' },
      { icon: 'chevron-right', symbol: '>' },
      { icon: 'char-part', symbol: '§' },
      { icon: 'music-accidental-sharp', symbol: '#' },
      { icon: 'char-tempo', symbol: '!' },
      { icon: 'char-comments', symbol: '// ' }
    ],
    subbar: null
  }),
  computed: {
    ...mapGetters('app', ['settings']),
    ...mapGetters('editor', ['editor']),

    editorLink() {
      const driveId = this.$route.params.driveId
      const scoreId = this.$route.params.scoreId
      return `/score/player/${driveId}/${scoreId}`
    }
  },
  mounted() {
    const template = this.$refs.template
    this.setDialog({ name: 'template', value: template })
    this.$nextTick(this.onResize)
  },
  methods: {
    ...mapActions('editor', [
      'insertChar',
      'undo',
      'redo',
      'copy',
      'paste',
      'togglePreview'
    ]),
    ...mapActions('app', ['setSettings']),
    ...mapMutations('editor', ['setDialog']),

    toggleChars() {
      this.showChars = !this.showChars
      this.$emit('input', this.showChars)
    },

    onResize() {
      const xs = this.$vuetify.breakpoint.thresholds.xs
      const hasEnoughWidth = window.innerWidth > xs
      const hasEnoughHeight = window.innerHeight > xs
      this.showPreview = hasEnoughWidth && hasEnoughHeight
    },

    prettifyCode() {
      const code = this.editor.getValue()
      const prettified = prettify(code)
      console.log('-----')
      console.log(prettified)
      this.editor.setValue(prettified)
    }
  }
}
</script>

<style scoped>
.editor-bar {
  display: flex;
  height: 100%;
}

.fluid-layout.horizontal .editor-bar {
  flex-direction: column;
}

.fluid-layout.horizontal .chars-bar {
  border-top: solid 1px var(--app-color-line);
}
.fluid-layout.vertical .chars-bar {
  border-left: solid 1px var(--app-color-line);
}
</style>
