<template>
  <!-- <v-dialog v-model="open" width="600" :retain-focus="false"> -->
  <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <app-icon value="code-copy"/>
      </v-btn>
    </template> -->
  <common-dialog
    ref="dialog"
    :trigger="proceed"
    title="Template"
    width="600"
    :retain-focus="false"
  >
    <div class="view" @keydown="onCardKeydown($event)">
      <!-- TEMPLATE -->
      <div class="caption">Template</div>
      <div
        ref="template"
        class="template"
        contenteditable
        @input="onTemplateInput($event)"
      />

      <!-- RAW (INPUT) -->
      <div class="caption">Input</div>
      <div ref="raw" class="raw" contenteditable @input="onRawInput($event)" />

      <!-- OUTPUT -->
      <div class="caption">Output</div>
      <div ref="output" class="output" @click="toClipboard()">
        {{ output }}
      </div>

      <!-- DESTINATION -->
      <div class="caption">Destination</div>
      <v-select
        class="destination"
        v-model="destination"
        :items="options"
        outlined
        hide-details
        dense
      />
    </div>
  </common-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { splitOut } from '../../../../jelly-flux/src/utils'
import CommonDialog from '../dialog/CommonDialog.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'TemplateDialog',
  components: {
    CommonDialog
  },
  data: () => ({
    template: '',
    raw: '',
    output: '',
    selection: {},
    destination: 'replace'
  }),
  computed: {
    ...mapGetters('editor', ['editor']),

    okLabel() {
      return this.selection.isCollapsed ? 'Insert' : 'Replace'
    },

    options() {
      const isCollapsed = this.selection.isCollapsed
      const options = [{ text: 'To clipboard', value: 'clipboard' }]
      if (!isCollapsed) {
        options.push(
          { text: 'After selection', value: 'after' },
          { text: 'Before selection', value: 'before' },
          { text: 'Replace', value: 'replace' }
        )
      } else {
        options.push({ text: 'Insert', value: 'replace' })
      }
      return options
    }
  },
  mounted() {
    this.updateOutput = debounce(this.debouncedUpdateOutput, 50)
  },
  methods: {
    ...mapActions('editor', ['insert']),

    apply() {
      const editor = this.editor
      if (this.output) {
        editor.replaceSelection(this.output)
      }
    },

    open() {
      this.$refs.dialog.open()
      setTimeout(() => {
        this.updateSelection()
        this.updateOutput()
      }, 5)
    },

    close() {
      setTimeout(() => {
        this.editor.focus()
      }, 5)
    },

    async proceed() {
      await this.insert({ content: this.output, destination: this.destination })
    },

    updateSelection() {
      const editor = this.editor
      const lines = editor.getSelection(false)
      const isCollapsed = lines.length === 1 && lines[0].length === 0
      if (!isCollapsed) {
        this.updateTemplate(lines[0])
      }
      const focus = this.template ? 'raw' : 'template'
      const el = this.$refs[focus]
      el.focus()
      this.selection = { lines, isCollapsed }
    },

    updateTemplate(template) {
      template = template.split('\n')[0]
      template = template.replace(/\sr\s/g, ' - ')
      template = template.replace(/\[r\s/g, '[- ')
      template = template.replace(/\sr\]/g, ' -]')
      template = template.replace(/[^[\s|\]-]+/g, '•')
      this.setEditable('template', template)
    },

    updateRaw(raw) {
      raw = raw.split('\n')[0]
      raw = raw.replace(/\|/g, '')
      raw = raw.replace(/[\s]+/g, ' ')
      const lastIsSpace = raw[raw.length - 1] === ' '
      raw = raw.trim()
      if (lastIsSpace) raw += '\xa0'
      this.setEditable('raw', raw)
    },

    setEditable(name, content) {
      const selection = window.getSelection()
      const charPos = selection.getRangeAt(0)?.endOffset || 0
      const el = this.$refs[name]
      const range = document.createRange()
      el.textContent = content
      selection.removeAllRanges()
      const offset = Math.min(charPos, content.length)
      range.setStart(el.firstChild || el, offset)
      selection.addRange(range)

      this[name] = content
      el.focus()
    },

    debouncedUpdateOutput() {
      const template = this.$refs.template.innerText
      const raw = this.$refs.raw.innerText
      const words = splitOut(raw, ' ', '[', ']', '`')
      let index = 0
      let output = ''
      if (raw) {
        output = template.replace(/•/g, () => {
          return (words[index++] || '').trim()
        })
      }
      this.output = output
    },

    onTemplateInput(event) {
      this.updateTemplate(event.currentTarget.innerText)
      this.updateOutput()
    },

    onRawInput() {
      this.updateRaw(event.currentTarget.innerText)
      this.updateOutput()
    },

    onCardKeydown(event) {
      if (event.key === 'Enter') {
        this.apply()
        this.proceed()
        event.preventDefault()
      }
    },

    toClipboard() {
      navigator.clipboard.writeText(this.output)
    }
  }
}
</script>

<style scoped>
.template,
.raw,
.output {
  padding: var(--app-gutter-sm);
  font-size: 14px;
  font-family: monospace;
  font-weight: 500;
  border-radius: 3px;
  border: solid 1px var(--app-color-line);
}

.output {
  min-height: 22px;
  box-sizing: content-box;
}

.template,
.raw,
.output {
  background: var(--app-color-layout);
  margin-bottom: var(--app-gutter-md);
}

.destination {
  background: var(--app-color-layout);
  margin: 0;
}

.destination /deep/ fieldset {
  border: solid 1px var(--app-color-line);
}
</style>
