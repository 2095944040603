<template>
  <div class="score" @contextmenu="openContextMenu($event)" v-resize="onResize">
    <context-menu ref="contextMenu" type="score" v-if="!hasEditor" />
    <switch-renderer-dialog />

    <!-- BREADCRUMB -->
    <breadcrumb-bar v-show="!headerHidden" v-if="!scoreExtends" />
    <!-- DRAWER -->
    <score-drawer v-if="hasPlayer" />

    <!-- PLAYER / EDITOR -->
    <split-layout
      :class="splitLayoutClass"
      :before="hasEditor"
      :after="hasPlayer"
      :horizontal="isHorizontal"
    >
      <template v-slot:before>
        <editor ref="editor"></editor>
      </template>
      <template v-slot:after>
        <player @user-select="onUserSelect($event)"></player>
      </template>
    </split-layout>

    <!-- FOOTER -->
    <score-footer v-if="showFooter" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Player from '../score/Player'
import Editor from '../score/Editor'
import ScoreFooter from '../score/ScoreFooter'
import ScoreDrawer from '../score/ScoreDrawer'

import SplitLayout from '../layout/SplitLayout'
import BreadcrumbBar from '../toolbar/BreadcrumbBar'
import ContextMenu from '../menu/ContextMenu.vue'
import SwitchRendererDialog from '../dialog/SwitchRendererDialog.vue'

import debounce from 'lodash/debounce'

export default {
  name: 'Score',
  components: {
    Player,
    Editor,
    ScoreFooter,
    ScoreDrawer,
    SplitLayout,
    ContextMenu,
    BreadcrumbBar,
    SwitchRendererDialog
  },
  props: {
    contextId: { type: String, default: 'player' },
    driveId: String,
    scoreId: String
  },
  data: () => ({
    isHorizontal: true,
    previewDisabled: false
  }),
  watch: {
    contextId() {
      this.update()
    },
    driveId() {
      this.update()
    },
    scoreId() {
      this.update()
    }
  },
  computed: {
    ...mapGetters('app', ['settings', 'headerHidden']),
    ...mapGetters('score', [
      'score',
      'report',
      'scoreExtends',
      'screenOptions'
    ]),
    ...mapGetters('editor', ['preview']),
    ...mapGetters('player', ['playing']),

    hasEditor() {
      return this.contextId === 'editor'
    },

    hasPreview() {
      return this.hasEditor && !this.previewDisabled && this.preview
    },

    hasPlayer() {
      return this.hasPreview || this.contextId === 'player'
    },

    showFooter() {
      return this.hasPlayer
    },

    splitLayoutClass() {
      return this.headerHidden || this.scoreExtends ? 'no-margin' : ''
    }
  },
  mounted() {
    if (!this.contextId) {
      this.$emit('update:contextId', 'player')
    }
    this.update = debounce(async function () {
      await this.openScore()
      this.onResize()
    })
    this.update()
    this.$nextTick(this.onResize)
  },
  destroyed() {
    this.closeScore()
  },
  methods: {
    ...mapActions('score', ['closeScore']),
    ...mapActions('player', ['play', 'stop']),
    ...mapActions('drive', ['openScore']),
    ...mapActions('editor', ['setSelectionFromRawPos']),

    onUserSelect({ rawPos }) {
      if (this.hasEditor) {
        //
        // Update code selection
        //
        this.setSelectionFromRawPos(rawPos)
      }
    },

    openContextMenu(event) {
      event.stopImmediatePropagation()
      this.$refs.contextMenu.open(this.score, event)
    },

    onResize() {
      const width = this.$el.clientWidth
      const height = this.$el.clientHeight
      const isVertical = width > height && width > 500
      const xs = this.$vuetify.breakpoint.thresholds.xs
      const hasEnoughWidth = window.innerWidth > xs
      const hasEnoughHeight = window.innerHeight > xs
      this.previewDisabled = !hasEnoughWidth || !hasEnoughHeight
      this.isHorizontal = !isVertical
    }

    // mouseDownHandler() {
    //   this._downTimeout = setTimeout(() => {
    //     if (this.playing) {
    //       this._wasPlaying = true
    //       this.stop()
    //     }
    //   }, 250)
    // },

    // mouseUpHandler() {
    //   if (this._downTimeout) {
    //     clearTimeout(this._downTimeout)
    //   }
    //   this._downTimeout = null
    //   if (this._wasPlaying) {
    //     this.play()
    //     // setTimeout(() => this.play(), 200)
    //   }
    // }
  }
}
</script>

<style scoped>
.score {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0;
  overflow: hidden;
}
.app-breadcrumb {
  position: absolute;
  top: 0;
}
.split-view {
  top: 0;
  bottom: 0;
}
.split-view:not(.no-margin) {
  top: 49px;
}

.part-bar {
  width: 100%;
  height: 24px;
}
.score-bar {
  width: 100%;
  height: 24px;
}
</style>
