class Line {}
class Paragraph extends Array {}
class Cell {}
class Col {}

export const prettify = (rawData) => {
  const lines = rawData.split('\n')
  //
  // Split into paragraphs
  //
  const paragraphs = []
  const lineRegex = /(^[HML!§#])\s+\|\s+(.+)/
  let paragraph, line, cell, col
  for (line of lines) {
    line = line.trimEnd()

    // remove multiple spaces
    line = line.replace(/\s+/g, ' ')
    // remove space after [
    line = line.replace(/\[\s/g, '[')
    // remove space before ]
    line = line.replace(/\s]/g, ']')
    // add space between ]|
    line = line.replace(/]\|/g, '] |')
    // add space between |[
    line = line.replace(/\|\[/g, '| [')
    // add space between ][
    line = line.replace(/]\[/g, '] [')
    // add space after [ or | and before word ex : ]toto => ] toto
    line = line.replace(/([\]|])([\w\-~_])/g, '$1 $2')
    // add space after word and before [ or | ex : toto[ => toto [
    line = line.replace(/([\w\-~_])([[|])/g, '$1 $2')

    const lineMatch = line.match(lineRegex)

    if (!paragraph) {
      paragraph = new Paragraph()
      paragraphs.push(paragraph)
    }
    if (!lineMatch) {
      if (paragraph.length) {
        paragraph = new Paragraph()
        paragraphs.push(paragraph)
      }
      if (!paragraph.out) {
        paragraph.out = []
      }
      paragraph.out.push([line])
      continue
    }
    if (paragraph.out) {
      paragraph = new Paragraph()
      paragraphs.push(paragraph)
    }
    let depth = 0
    line = new Line()
    line.context = lineMatch[1]
    line.content = lineMatch[2]
    line.cells = line.content.split(' ').map((content) => {
      const cell = new Cell()
      let charIndex = 0
      cell.brackets = { in: 0, out: 0 }
      cell.depth = { in: depth, out: depth }
      while (content.charAt(charIndex) === '[') {
        depth = ++cell.depth.out
        cell.brackets.in++
        charIndex++
      }
      charIndex = content.length - 1
      while (content.charAt(charIndex) === ']') {
        depth = --cell.depth.out
        cell.brackets.out--
        charIndex--
      }
      cell.content = content.slice(
        cell.brackets.in,
        cell.brackets.out || undefined
      )

      return cell
    })
    line.pointer = 0
    paragraph.push(line)
  }
  //
  //
  //
  for (paragraph of paragraphs) {
    if (paragraph.out) {
      continue
    }
    paragraph.cols = []
    paragraph.depthByLine = []
    paragraph.depth = 0
    paragraph.eol = []
    paragraph.eolCount = 0

    const lineCount = paragraph.length
    let loop = 1
    let lastCol
    while (paragraph.eolCount < paragraph.length) {
      col = new Col()
      col.cellByLine = []
      col.brackets = { in: 0, out: 0 }
      col.maxChar = 0
      paragraph.cols.push(col)

      for (let lineIndex = 0; lineIndex < lineCount; lineIndex++) {
        if (paragraph.eol[lineIndex]) {
          continue
        }
        line = paragraph[lineIndex]
        cell = line.cells[line.pointer]

        if (cell.depth.in >= paragraph.depth) {
          col.cellByLine[lineIndex] = cell
          col.maxChar = Math.max(col.maxChar, cell.content.length)
          paragraph.depthByLine[lineIndex] = cell.depth.out
          if (line.pointer === line.cells.length - 1) {
            paragraph.eol[lineIndex] = true
            paragraph.eolCount++
          } else {
            line.pointer++
          }
        } else {
          // The nextcell require a depth lower than the current depth of the
          // paragraph. It can be insered. But we need to update the backet of
          // the last cell of the same line
          const lastCell = lastCol.cellByLine[lineIndex]
          const tailCell = new Cell()
          tailCell.isTail = true
          tailCell.brackets = { in: 0, out: lastCell.brackets.out }
          tailCell.content = 0

          lastCell.brackets.out = 0
          lastCell.tail = tailCell
          col.cellByLine[lineIndex] = tailCell
          cell = tailCell
        }
      }

      paragraph.depth = paragraph.depthByLine.reduce(
        (a, v) => Math.max(a, v),
        0
      )

      lastCol = col
      if (loop++ > 10000) {
        console.warn('Infinite loop !')
        break
      }
    }
  }

  //
  // Build the output
  //
  paragraphs.forEach((paragraph) => {
    if (!paragraph.out) {
      paragraph.out = []
      paragraph.cols.forEach((col) => {
        col.cellByLine.forEach((cell) => {
          col.brackets.in = Math.max(col.brackets.in, cell.brackets.in)
          col.brackets.out = Math.max(col.brackets.out, -cell.brackets.out)
        })
        col.cellByLine.forEach((cell, lineIndex) => {
          let out = paragraph.out[lineIndex]
          if (!out) {
            out = []
            paragraph.out[lineIndex] = out
          }
          let content = cell.content || ''
          content = content.padEnd(col.maxChar, ' ')
          const entries = ['in', 'out']
          entries.forEach((entry) => {
            const colEntry = col.brackets[entry]
            let aside = ''
            if (colEntry && cell.brackets) {
              const cellEntry = Math.abs(cell.brackets[entry])
              const char = entry === 'in' ? '[' : ']'
              aside = char.repeat(cellEntry)
            }
            if (entry === 'in') {
              content = aside.padEnd(colEntry) + content
            } else {
              content = content + aside.padStart(colEntry)
            }
          })
          content += ' '
          out.push(content)
        })
      })
    }

    const content = paragraph.out.reduce((a, v, i) => {
      const prefix = paragraph.length ? `${paragraph[i].context} | ` : ''
      return a + prefix + v.join('') + '\n'
    }, '')
    paragraph.out.content = content
  })

  console.log(paragraphs)

  return paragraphs
    .map((p) => p.out.content)
    .join('\n')
    .replace(/\n{2,}/g, '\n\n')
}
