<template>
  <v-navigation-drawer
    class="score-drawer"
    right
    clipped
    :value="showDrawer"
    app
    :mobile-breakpoint="0"
    :width="drawerWidth"
    v-resize="resizeHandler"
  >
    <v-btn-toggle class="drawer-bar" v-model="buttonGroup" tile borderless>
      <bar-button icon="guitar-diagram" />
      <bar-button icon="piano" />
    </v-btn-toggle>
    <diagrams :type="diagram" :size="diagramSize" />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import BarButton from '../button/BarButton.vue'
import Diagrams from '../score/Diagrams.vue'

export default {
  name: 'ScoreDrawer',
  components: {
    BarButton,
    Diagrams
  },

  data: () => ({
    buttonGroup: 0,
    drawerWidth: 0,
    diagramSize: 'md'
  }),

  computed: {
    ...mapGetters('app', ['settings']),
    ...mapGetters('score', ['report', 'scoreSettings', 'screenOptions']),
    ...mapGetters('player', ['headEvent', 'progress']),

    showDrawer() {
      return this.screenOptions.indexOf('diagram') > -1
    },
    chordProgress() {
      const fromToNext = this.toTime - this.fromTime
      const fromToHead = this.progress - this.fromTime
      return fromToNext && fromToHead ? fromToHead / fromToNext : 0
    },

    diagram() {
      return this.settings.info.panel === 0 ? 'guitar' : 'piano'
    }
  },
  watch: {
    buttonGroup(value) {
      this.setSettings(['info/panel', value])
    },
    'settings.info.panel': {
      handler(value) {
        return (this.buttonGroup = value)
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('app', ['setSettings']),

    resizeHandler() {
      const width = window.innerWidth
      if (width < 600) {
        // xs
        this.diagramSize = 'xs'
        this.drawerWidth = 120
      } else if (width < 960) {
        // sm
        this.diagramSize = 'sm'
        this.drawerWidth = 180
      } else {
        this.diagramSize = 'md'
        this.drawerWidth = 240
      }
    }
  }
}
</script>

<style scoped>
.score-drawer {
  height: 100%;
  background: var(--app-color-layout);
}
.score-drawer /deep/ .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}

/* PANEL BAR */

.drawer-bar {
  border-bottom: solid 1px var(--app-color-line);
}
.v-btn-toggle > .bar-button.v-btn {
  flex: 1;
  max-width: initial !important;
  background-color: var(--app-color-layout) !important;
  opacity: 1;
}
.v-btn /deep/ .app-icon {
  opacity: 0.7;
}

.bar-button:first-child {
  border-right: solid 1px var(--app-color-line) !important;
}
.bar-button:before {
  opacity: 0 !important;
}

.diagrams {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--app-gutter-md);
}

.separator {
  width: 10px;
  margin-top: var(--app-gutter-md);
  margin-bottom: var(--app-gutter-sm);
  border-bottom: solid 1px #00000050;
}
</style>
