<template>
  <common-dialog ref="dialog" no-action width="400">
    <div class="dialog-message">Oups, the score cannot be rendered.</div>

    <div class="dialog-message">
      The <i>{{ details.renderer }}</i> mode (<app-icon
        :value="details.icon"
      />) require <i>{{ details.missing }}</i
      >. The current score contains only
      <i>{{ details.tagLabel }}</i> (<score-tags :tags="details.tags" />).
    </div>
    <v-btn
      v-for="(renderer, rendererIndex) in renderers"
      :key="rendererIndex"
      color="accent"
      @click="changeRenderer(renderer)"
      >Switch to {{ renderer.label }}</v-btn
    >
  </common-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import CommonDialog from './CommonDialog'
import AppIcon from '../misc/AppIcon.vue'
import ScoreTags from '../score/ScoreTags.vue'

export default {
  name: 'SwitchRendererDialog',
  components: { CommonDialog, AppIcon, ScoreTags },
  data: () => ({
    renderers: [],
    details: ''
  }),
  computed: {
    ...mapGetters('score', ['report', 'renderer'])
  },
  watch: {
    report(value) {
      if (!value) return ''
      const renderer = this.renderer
      const report = value
      const missing = []
      switch (renderer) {
        case 'lyric':
          if (!report.hasLyric && !report.hasHarmony) {
            if (!report.hasLyric) missing.push('lyric')
            if (!report.hasHarmony) missing.push('harmony')
          }
          break
        case 'chart':
          if (!report.hasMetric) missing.push('metric')
          if (!report.hasHarmony) missing.push('harmony')
          break
        case 'staff':
          if (!report.hasMetric) missing.push('metric')
          if (!report.hasNote) missing.push('note')
          break
      }

      let renderers = []
      if (missing.length) {
        if (report.hasNote && report.hasMetric) {
          renderers.push({ name: 'staff', label: 'Staff Mode' })
        }
        if (report.hasLyric) {
          renderers.push({ name: 'lyric', label: 'Lyric Mode' })
        }
        if (report.hasHarmony && report.hasMetric) {
          renderers.push({ name: 'chart', label: 'Chart Mode' })
        }
      }
      this.renderers = renderers
      if (this.renderers.length) {
        const tags = []
        if (report.hasLyric) tags.push('lyric')
        if (report.hasHarmony) tags.push('harmony')
        if (report.hasNote) tags.push('melody')
        if (report.hasMetric) tags.push('metric')
        let tagLabel = ''
        if (tags.length > 1) {
          const headTag = tags.slice(0, -1)
          const lastTag = tags[tags.length - 1]
          tagLabel = `${headTag.join(', ')} and ${lastTag}`
        } else {
          tagLabel = tags[0]
        }
        this.details = {
          renderer: renderer,
          icon:
            renderer === 'staff'
              ? 'score-display'
              : `score-display-${renderer}`,
          missing: missing.join(' and '),
          tagLabel,
          tags
        }
        this.open()
      }
    }
  },
  methods: {
    ...mapActions('app', ['setSettings']),

    open: function () {
      this.$refs.dialog.open()
    },

    changeRenderer(renderer) {
      this.setSettings(['score/renderer', renderer.name])
      this.$refs.dialog.close()
    }
  }
}
</script>
<style scoped>
.dialog-message:first-child {
  color: black;
  font-size: large;
}
.dialog-message {
  margin-bottom: var(--app-gutter-md);
}
.app-icon {
  display: inline-block;
  margin-top: -9px;
}
.app-icon /deep/ svg {
  transform: translateY(8px);
}
.score-tags {
  display: inline-block;
  margin: 0 2px;
}
.score-tags /deep/ span:not(:last-child) {
  margin-right: var(--app-gutter-xs);
  padding-right: 1px;
}
.v-btn:not(:last-child) {
  margin-bottom: var(--app-gutter-sm);
}
</style>
