<template>
  <div :class="splitViewClass">
    <div v-if="before" class="panel before" :style="beforeStyle">
      <slot name="before"></slot>
    </div>

    <div
      v-if="hasDivider"
      :class="dividerClasses"
      :style="dividerStyle"
      @mousedown.stop="onMousedown"
      @touchstart.stop="onTouchsart"
    ></div>

    <div v-if="after" class="panel after" :style="afterStyle">
      <slot name="after"></slot>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  name: 'SplitView',
  props: {
    before: Boolean,
    after: Boolean,
    horizontal: Boolean
  },
  data: () => ({
    divider: { float: null, offset: 0, move: null, down: null, min: 0, max: 0 }
  }),

  computed: {
    splitViewClass() {
      const result = ['split-view']
      if (this.before && this.after) {
        result.push(this.horizontal ? 'horizontal' : 'vertical')
      } else {
        result.push('single')
      }
      return result
    },

    hasDivider() {
      return this.before && this.after
    },

    beforeStyle() {
      return this.getPanelStyle()
    },

    afterStyle() {
      return this.getPanelStyle(true)
    },

    dividerStyle() {
      let offset = this.divider.offset
      if (this.divider.float) {
        offset += this.divider.move
      }
      const prop = this.horizontal ? 'translateY' : 'translateX'
      return `transform:${prop}(${offset}px)`
    },

    dividerClasses() {
      return this.divider.float ? 'divider floating' : 'divider'
    }
  },
  watch: {
    before() {
      this.debouncedResize()
    },
    after() {
      this.debouncedResize()
    },
    horizontal() {
      this.debouncedResize()
    }
  },
  mounted() {
    this.debouncedResize = debounce(() => {
      const resizeEvent = new UIEvent('resize')
      window.dispatchEvent(resizeEvent)
    }, 100)
    this.setListeners()
  },
  destroyed() {
    this.setListeners(true)
  },
  methods: {
    setListeners(remove) {
      const names = ['Touchmove', 'Touchend', 'Mousemove', 'Mouseup']
      names.forEach((e, i) => {
        const f = document[remove ? 'removeEventListener' : 'addEventListener']
        const opts = i === 0 && !remove ? { passive: false } : undefined
        f(e.toLowerCase(), this['on' + e], opts)
      })
    },

    getPanelStyle(isAfter) {
      const single = !this.before || !this.after
      const prop = this.horizontal ? 'height' : 'width'
      if (single) {
        return { [prop]: '100%' }
      } else {
        const offset = this.divider.offset * (isAfter ? -1 : 1)
        return { [prop]: `calc(50% + ${offset}px)` }
      }
    },

    onTouchsart(evt) {
      this.callHandler(evt, this.onMousedown)
    },
    onTouchend(evt) {
      this.callHandler(evt, this.onMouseup)
    },
    onTouchmove(evt) {
      this.callHandler(evt, this.onMousemove)
    },
    callHandler(evt, handler) {
      const beforeTouch = evt.changedTouches[0]
      if (beforeTouch) {
        handler(beforeTouch)
      }
    },
    onMousedown(evt) {
      const bound = this.$el.getBoundingClientRect()
      const offset = this.divider.offset
      const boundProp = this.horizontal ? 'height' : 'width'
      const min = this.horizontal ? 145 : 289
      this.divider.float = true
      this.divider.down = this.horizontal ? evt.y : evt.x
      this.divider.min = -bound[boundProp] / 2 + min - offset
      this.divider.max = bound[boundProp] / 2 - min - offset
      evt.preventDefault()
      evt.stopImmediatePropagation()
    },
    onMouseup() {
      if (this.divider.float) {
        this.divider.offset += this.divider.move
        this.divider.float = false
        this.divider.down = null
        this.divider.move = null
        //
        // Dispatch a resize event.
        //
        this.$nextTick(() => {
          const resizeEvent = new UIEvent('resize')
          window.dispatchEvent(resizeEvent)
        })
      }
    },
    onMousemove(evt) {
      if (this.divider.float) {
        let move = this.horizontal ? evt.y : evt.x
        move -= this.divider.down
        move = Math.max(move, this.divider.min)
        move = Math.min(move, this.divider.max)
        this.divider.move = move
      }
    }
  }
}
</script>

<style scoped>
.split-view {
  position: absolute;
  width: 100%;
}

/* PANELS */

.panel {
  position: absolute;
  min-width: 289px;
}
.split-view.single .panel {
  height: 100%;
  width: 100%;
}
.split-view.vertical .panel {
  height: 100%;
  min-width: 145px;
}
.split-view.vertical .after {
  right: 0;
  border-left: solid 1px var(--app-color-line);
}
.split-view.horizontal .panel {
  width: 100%;
  height: 50%;
}
.split-view.horizontal .after {
  bottom: 0%;
  border-top: solid 1px var(--app-color-line);
}

/* .before {
  background-color: var(--app-color-paper);
} */

/* DIVIDER */

.divider {
  background: transparent;
  position: absolute;
  z-index: 10;
  margin: 0 !important;
}
.divider.floating:after {
  content: '';
  position: absolute;
}
.split-view.vertical .divider {
  width: 8px;
  top: 0;
  left: 50%;
  bottom: 0;
}
.split-view.vertical .divider.floating:after {
  width: 1px;
  left: 0;
  top: 0;
  height: 100%;
  border-left: dashed 1px #00000033;
}
.split-view.vertical .divider:hover {
  cursor: ew-resize;
}
.split-view.horizontal .divider {
  height: 8px;
  left: 0;
  top: 50%;
  right: 0;
}
.split-view.horizontal .divider.floating:after {
  height: 1px;
  top: 0;
  left: 0;
  width: 100%;
  border-top: dashed 1px #00000033;
}
.split-view.horizontal .divider:hover {
  cursor: ns-resize;
}
</style>
