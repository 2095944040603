<template>
  <fluid-layout class="editor" :extended="extended" :locked="barLocked">
    <!-- BAR -->
    <template v-slot:bar="{ landscape, layout }">
      <editor-bar :landscape="landscape" :layout="layout" v-model="extended" />
    </template>
    <!-- CONTENT -->
    <template v-slot:content>
      <div class="wrapper">
        <textarea ref="textarea" />
      </div>
    </template>
  </fluid-layout>
</template>

<script>
import CodeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/mode/simple.js'
import 'codemirror/addon/selection/mark-selection.js'
import 'codemirror/addon/edit/closebrackets.js'
import 'codemirror/addon/edit/matchbrackets.js'
import 'codemirror/addon/search/searchcursor.js'

import FluidLayout from '../layout/FluidLayout.vue'
import EditorBar from '../toolbar/EditorBar.vue'

import { mapGetters, mapMutations, mapActions } from 'vuex'

import { manifest } from '../../../../jelly-sheet/src/settings/SheetSettings'

import debounce from 'lodash/debounce'

export default {
  name: 'Editor',
  components: { FluidLayout, EditorBar },
  data: () => ({
    isSaving: false,
    extended: false,
    barLocked: false
  }),
  computed: {
    ...mapGetters('score', ['score']),
    ...mapGetters('editor', ['dialogs'])
  },
  watch: {
    'score.raw'() {
      this.setFromStore()
    }
  },
  mounted() {
    const f = async function () {
      this.isSaving = true
      await this.saveDraft()
      this.isSaving = false
    }
    this.autoSaveDraft = debounce(f, 1000, { trailing: true })
    this.createEditor()
    this.setFromStore()
  },
  destroyed() {
    this.destroyEditor()
  },

  methods: {
    ...mapMutations('app', ['setHeaderHidden']),
    ...mapMutations('score', ['setRaw']),
    ...mapActions('score', ['saveDraft']),
    ...mapMutations('editor', ['setEditor']),

    createEditor() {
      const options = this.getOptions()
      const editor = CodeMirror.fromTextArea(this.$refs.textarea, options)
      editor.on('change', () => {
        this.setFromEditor()
        this.historySize = this._editor.doc.historySize()
      })
      editor.on('focus', () => {
        this.barLocked = true
        if (this.$el.clientHeight < 300) this.setHeaderHidden(true)
      })
      editor.on('blur', () => {
        this.barLocked = false
        this.setHeaderHidden(false)
      })
      editor.on('cursorActivity', () => {
        const cursor = editor.getCursor()
        if (cursor.line != this._cursor.line || cursor.ch != this._cursor.ch) {
          this.$emit('user-cursor', { line: cursor.line, ch: cursor.ch })
          this._cursor = Object.assign({}, cursor)
        }
      })
      this.setEditor(editor)
      this._editor = editor
      this._cursor = { line: -1, ch: -1 }
    },

    getEditor() {
      return this._editor
    },

    destroyEditor() {
      const element = this._editor.doc.cm.getWrapperElement()
      element && element.remove && element.remove()
      this._editor = null
      this._cursor = null
      this.setEditor(null)
    },

    setFromStore() {
      const currentCode = this._editor.getValue()
      const newCode = this.score?.raw || ''
      if (newCode && newCode != currentCode) {
        this._editor.setValue(newCode)
        this._editor.doc.clearHistory()
      }
    },

    setFromEditor() {
      const value = this._editor.getValue()
      this.setRaw(value)
      this.autoSaveDraft()
    },

    getOptions() {
      this.buildMode()
      return {
        tabSize: 2,
        lineNumbers: true,
        line: true,
        foldGutter: true,
        mode: { name: 'custom' },
        lineWrapping: false,
        styleSelectedText: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        extraKeys: this.getExtraKeys()
      }
    },

    getExtraKeys() {
      return {
        'Ctrl-Up': (cm) => cm.replaceSelection('↑'),
        'Ctrl-Down': (cm) => cm.replaceSelection('↓')
      }
    },

    buildMode() {
      const mode = {
        start: [
          { regex: /\/\/.*/, token: 'comment' },
          { regex: /\s+$/, sol: true, token: 'default' }
        ],
        meta: {
          lineComment: '//'
        }
      }
      manifest.forEach((m) => {
        let symbol = m.symbol
        const regStart = new RegExp(`${symbol}[\\s|]*`)
        const token = m.name
        mode.start.push({
          regex: regStart,
          token,
          sol: true
        })
      })
      mode.start.push({ regex: /.+/, sol: true, token: 'unknown' })
      mode.start.push({ regex: /[`[()\]|]\s*/, token: 'delimiter' })
      mode.start.push({ regex: /[*>~_·-]\s*/, token: 'empty' })
      mode.start.push({
        regex: /[°«»\p{Script=Latin}0-9+↑↓#!?,;':"./\s]+\s*/u,
        token: 'default'
      })
      mode.start.push({ regex: /.+/, token: 'unknown' })
      CodeMirror.defineSimpleMode('custom', mode)
    }
  }
}
</script>

<style scoped>
.editor {
  height: 100%;
  width: 100%;
  --app-selection-color: #f9d9b9;
}
.wrapper {
  height: 100%;
}

/* CODE MIRROR */

.editor /deep/ .CodeMirror {
  height: 100%;
  width: 100%;
  background-color: var(--app-color-paper);
  color: #f8f8f2 !important;
  font-size: 13px;
  line-height: 15px;
  font-family: Monospace;
  font-weight: 500;
}

.theme--light .editor /deep/ .CodeMirror {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.75) !important;
}

.editor /deep/ .CodeMirror-cursor {
  border-left: 2px solid #666666;
}

.editor /deep/ .CodeMirror-code {
  /* FOR MOBILE */
  caret-color: black;
}

/* GUTTER */

.editor /deep/ .CodeMirror-gutters {
  background-color: var(--app-color-layout) !important;
  color: #f8f8f2 !important;
}
.theme--light .editor /deep/ .CodeMirror-gutters {
  background-color: #ebebeb !important;
}

/* HIGHLIGHT */

/* .editor /deep/ .CodeMirror-line > * {
  color: white;
  background: red;
  padding-right: 0.01px !important;
} */

.editor /deep/ .cm-tune,
.editor /deep/ .cm-tempo,
.editor /deep/ .cm-lyric,
.editor /deep/ .cm-part,
.editor /deep/ .cm-chord,
.editor /deep/ .cm-note,
.editor /deep/ .cm-lyric,
.editor /deep/ .cm-comment,
.editor /deep/ .cm-default,
.editor /deep/ .cm-delimiter,
.editor /deep/ .cm-empty {
  background: var(--app-color-paper);
}

.editor /deep/ .cm-tune,
.editor /deep/ .cm-tune ~ span {
  color: grey;
  font-weight: 400;
}
.editor /deep/ .cm-tempo,
.editor /deep/ .cm-tempo ~ span {
  color: grey;
  font-weight: 400;
}

.editor /deep/ .cm-part,
.editor /deep/ .cm-part ~ span {
  color: grey;
}
.editor /deep/ .cm-part ~ span:last-child {
  border-right: solid 8px var(--app-color-paper);
}
.editor /deep/ .cm-part ~ span:last-child:after {
  content: ' ';
  position: absolute;
  background: lightgray;
  height: 1px;
  top: 50%;
  right: 10px;
  left: 10px;
  z-index: -1;
}

.editor /deep/ .cm-chord,
.editor /deep/ .cm-chord ~ span {
  color: hsl(205deg 59% 44%);
}
.editor /deep/ .cm-note,
.editor /deep/ .cm-note ~ span {
  color: hsl(324deg 45% 50%);
}
.editor /deep/ .cm-lyric,
.editor /deep/ .cm-lyric ~ span {
  color: hsl(147deg 100% 31%);
}

.editor /deep/ .cm-comment {
  font-style: italic;
  color: lightgrey;
}

.editor /deep/ .cm-empty {
  color: rgb(204, 204, 204) !important;
}
.editor /deep/ .cm-delimiter {
  color: grey !important;
}

.editor /deep/ .cm-unknown {
  color: white !important;
  background: red;
}

/* ADDONS */

.editor /deep/ .CodeMirror-matchingbracket {
  background: lightgrey !important;
  color: grey !important;
  padding-bottom: 2px;
}

/* SELECTION */

.editor /deep/ .CodeMirror-selected {
  background: var(--app-selection-color);
}
.editor /deep/ .CodeMirror-focused .CodeMirror-selected {
  background: var(--app-selection-color);
}
.editor /deep/ .CodeMirror-selectedtext:not(.cm-unknown) {
  background: var(--app-selection-color) !important;
  border-color: var(--app-selection-color) !important;
}
</style>
